<template>
  <div>
    <!--begin::Synchronize products-->
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('nav.aside.products.sync') }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('products.sync.desc') }}
            </span>
          </h2>
        </b-card-title>
        <div class="card-toolbar">
          <b-btn
            ref="sync_btn"
            variant="primary"
            class="py-3 px-4"
            size="md"
            :disabled="syncIsActive"
            :class="syncIsActive ? 'spinner spinner-white spinner-left' : ''"
            @click="startSync"
          >
            <span v-if="!syncIsActive" class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Update.svg"></inline-svg>
            </span>
            <span v-if="!syncIsActive">{{ trans('products.sync.start') }}</span>
            <span v-else class="ml-10">{{ trans('products.sync.running') }}</span>
          </b-btn>
        </div>
      </div>
      <div class="card-body">
        <!--begin::sync-progress-->
        <div class="d-flex w-100">
          <!--begin::start-end-sync-->
          <div class="d-flex mr-10">
            <div class="mr-6">
              <div class="font-weight-bold mb-2">
                {{ trans('products.sync.started') }}
              </div>
              <span class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold">
                {{ lastSyncStart }}
              </span>
            </div>
            <div>
              <div class="font-weight-bold mb-2">
                {{ trans('products.sync.ended') }}
              </div>
              <span v-if="!syncIsActive" class="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold">
                {{ lastSyncEnd }}
              </span>
              <span v-else class="btn btn-sm btn-text btn-light-warning text-uppercase font-weight-bold">
                {{ trans('products.sync.active') }}
              </span>
            </div>
          </div>
          <!--end::start-end-sync-->
          <!--begin::progressbar-->
          <div class="flex-grow-1 flex-shrink-0 mt-4 mt-sm-0">
            <span class="font-weight-bold">
              {{ trans('products.sync.progress') }}
            </span>
            <div class="progress progress-xs mt-2 mb-2">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="{ width: syncProgress + '%'}"
                :aria-valuenow="syncProgress"
                aria-valuemin="0"
                aria-valuemax="100"
              >
              </div>
            </div>
            <div class="font-weight-bolder text-dark">
              {{ syncProgress }}%
            </div>
          </div>
          <!--end::progressbar-->
        </div>
        <!--end::sync-progress-->
        <div class="separator separator-solid my-7"></div>
        <!--begin::sync-details-->
        <div class="d-flex align-items-center flex-wrap">
          <!--begin::total-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.total') }}
            </span>
            <span class="font-weight-bolder font-size-h5">
              {{ syncTotal }}
            </span>
            </div>
          </div>
          <!--end::total-->

          <!--begin::processed-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.processed') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ syncProcessed }}
            </span>
            </div>
          </div>
          <!--end::processed-->

          <!--begin::unchanged-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-info">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.unchanged') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ syncUnchanged }}
            </span>
            </div>
          </div>
          <!--end::unchanged-->

          <!--begin::new-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-success">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.new') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ syncNew }}
            </span>
            </div>
          </div>
          <!--end::new-->

          <!--begin::updated-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-warning">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.updated') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ syncUpdated }}
            </span>
            </div>
          </div>
          <!--end::updated-->

          <!--begin::failed-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div class="d-flex flex-column text-danger">
            <span class="font-weight-bolder font-size-sm">
              {{ trans('products.sync.failed') }}
            </span>
              <span class="font-weight-bolder font-size-h5">
              {{ syncFailed }}
            </span>
            </div>
          </div>
          <!--end::failed-->
        </div>
        <!--end::sync-details-->
      </div>
    </b-card>
    <!--end::Synchronize products-->

    <!--begin::Synchronize products-->
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('products.sync.log.title') }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('products.sync.log.desc') }}
            </span>
          </h2>
        </b-card-title>
        <div class="card-toolbar">

        </div>
      </div>
      <div class="card-body" style="min-height: 80px;">
        <b-table
          id="synclog"
          primary-key="syncid"
          :fields="fields"
          :items="items"
          responsive="md"
        >
          <!--begin::title-->
          <template
            v-if="listProps.loading"
            v-slot:cell(id)
            class="log-id"
          >
            <div class="list-item loading rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(id)="data"
          >
            {{ data.item.id }}
          </template>
          <!--end::title-->

          <!--begin::count-->
          <template
            v-if="listProps.loading"
            v-slot:cell(count)
            class="log-count"
          >
            <div class="list-item loading rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(count)="data"
          >
            {{ data.item.count }}
          </template>
          <!--end::count-->

          <!--begin::count-->
          <template
            v-if="listProps.loading"
            v-slot:cell(status)
            class="log-count"
          >
            <div class="list-item loading rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(status)="data"
          >
            <span
              class="label label-lg font-weight-bold label-inline"
              :class="`label-light-${data.item.status}`"
            >
              <span v-if="data.item.status === 'success'">
                {{ transChoice('global.successful', 0) | capitalize }}
              </span>
              <span v-else>
                {{ transChoice('global.unknown', 0) | capitalize }}
              </span>
            </span>
          </template>
          <!--end::count-->

          <!--begin::createdon-->
          <template
            v-if="listProps.loading"
            v-slot:cell(createdon)
            class="log-createdon"
          >
            <div class="list-item loading rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(createdon)="data"
          >
            {{ momentFullDate(data.item.createdon) }} @ {{ momentHoursMinutesSeconds(data.item.createdon) }}
          </template>
          <!--end::createdon-->

          <!--begin::actions-->
          <template
            v-if="listProps.loading"
            v-slot:cell(actions)
            class="log-actions"
          >
            <div class="list-item loading rounded"></div>
          </template>

          <template
            v-else
            v-slot:cell(actions)="data"
          >
            <b-button
              :ref="'logentry-view-' + data.item.id"
              variant="light-primary"
              size="sm"
              :to="'/logs/products/sync/' + data.item.id"
            >
              <span class="svg-icon svg-icon-lg">
                <inline-svg :src="'/media/svg/icons/General/Search.svg'" />
              </span>
              <span>
                {{ transChoice('global.details', 2) | capitalize }}
              </span>
            </b-button>
          </template>
          <!--end::createdon-->
        </b-table>

        <!--begin::more-btn-->
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="d-flex align-items-center py-3">
            <div class="d-flex align-items-center">
              <Loader
                v-if="listProps.loading"
                :text="trans('products.sync.log.btn.loadMore')"
                loader-class="relative"
                loader-style="margin-right: 80px;"
              ></Loader>
              <b-btn
                v-else-if="listProps.showMoreBtn"
                ref="logs_showmorebtn"
                variant="primary"
                class="py-4 px-6"
                size="sm"
                @click="loadMore(listProps.lastVisibleDoc)"
              >
                {{ trans('products.sync.log.btn.loadMore') }}
              </b-btn>
            </div>
          </div>
        </div>
        <!--end::more-btn-->
      </div>
    </b-card>
    <!--end::Synchronize products-->
  </div>
</template>


<script>
  import * as objectPath from 'object-path'
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { ref, onBeforeMount, onMounted, onUnmounted, computed } from '@vue/composition-api'

  import useDateTime from '@src/use/datetime'
  import { capitalize } from '@src/core/helpers/textUtils'
  import { TASKS } from '@src/store/tasks'

  export default {
    name: 'ProductsIndex',
    components: { Loader: () => import('@src/components/content/Loader') },
    setup (props, { root, refs }) {
      /**
       * Sync and live updates specific
       */
      let snapDocUnsubscribe = null
      const syncIsActive = ref(false)
      const lastSyncStart = ref('')
      const lastSyncEnd = ref('')
      const syncProgress = ref(0)
      const syncTotal = ref(0)
      const syncProcessed = ref(0)
      const syncUnchanged = ref(0)
      const syncFailed = ref(0)
      const syncNew = ref(0)
      const syncUpdated = ref(0)

      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      const listProps = ref({
        limit: 10,
        loading: true,
        orderBy: 'createdOn',
        sortOrder: 'desc',
        lastVisibleDoc: true,
        showMoreBtn: true,
        type: 'productsync'
      })

      const listItems = ref(new Array(listProps.value.limit).fill({ })) // Create placeholders while loading

      const loadMore = async (paginateDoc) => {
        const btn = refs.logs_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getLogs = fb.functions.httpsCallable('backendLogsGetLogs')

        return await getLogs({
          data: {
            limit: listProps.value.limit,
            orderBy: listProps.value.orderBy, // disabled - TODO: Fix dates in backend
            sort: listProps.value.sortOrder,
            type: listProps.value.type,
            paginateDoc
          }
        }).then((res) => {
          if (listProps.value.loading) listItems.value = []
          listProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= listProps.value.limit // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            listItems.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          listProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      const enableLiveSnapshot = () => {
        const snapDoc = fb.snapsCollection.doc('backendProductsSynchronizeProducts')
        snapDocUnsubscribe = snapDoc.onSnapshot((doc) => {
          if (doc.exists) {
            const snapDocData = doc.data()
            syncIsActive.value = objectPath.get(snapDocData, 'running', false)

            // Refresh the synclog-list when the sync is done
            if (!syncIsActive.value) {
              listItems.value = [] // Reset all items
              loadMore(true).then(() => {
                listProps.value.loading = false
              })
            }

            // Dates
            const startDate = objectPath.get(snapDocData, 'createdOn', new Date())
            const endDate = objectPath.get(snapDocData, 'endedOn', new Date())
            lastSyncStart.value = `${ momentFullDate(startDate) } @ ${ momentHoursMinutesSeconds(startDate) }`
            lastSyncEnd.value = `${ momentFullDate(endDate) } @ ${ momentHoursMinutesSeconds(endDate) }`

            // Totals
            const totalProducts = objectPath.get(snapDocData, 'data.total', 1)
            const processedProducts = objectPath.get(snapDocData, 'data.processed', totalProducts)
            syncProcessed.value = processedProducts
            syncTotal.value = objectPath.get(snapDocData, 'data.total', 0)

            // Percentage
            syncProgress.value = Math.floor(parseInt(processedProducts, 10) / parseInt(totalProducts, 10) * 100) // eslint-disable-line max-len

            // Statuses
            syncUnchanged.value = objectPath.get(snapDocData, 'data.unchanged', []).length
            syncFailed.value = objectPath.get(snapDocData, 'data.failed', []).length
            syncNew.value = objectPath.get(snapDocData, 'data.new', []).length
            syncUpdated.value = objectPath.get(snapDocData, 'data.updated', []).length
          } else {
            // The snapshotdoc does not exist
            loadMore(true).then(() => {
              listProps.value.loading = false
            })
          }
        })
      }

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
        if (snapDocUnsubscribe !== null) snapDocUnsubscribe() // Unlink the live snapshot
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('nav.aside.products.title'),
            route: '/products'
          },
          { title: trans('nav.aside.products.sync') }
        ])
        enableLiveSnapshot()
      })

      onUnmounted(() => {
        if (snapDocUnsubscribe !== null) snapDocUnsubscribe() // Unlink the live snapshot
      })

      const startSync = () => {
        syncIsActive.value = true
        const initializeSync = fb.functions.httpsCallable('backendProductsSynchronizeProducts')

        initializeSync().then((res) => {
          console.log('Sync returned data:', res)
          // Update the "oppgaver"-count
          root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len
        }).catch((err) => {
          console.log('There was a error while starting the sync', err)
        })
        // }).finally(() => {
        //   syncIsActive.value = false
        // })
      }

      const items = computed(() => listItems.value.map((entry) => ({
        id: objectPath.get(entry, 'id', ''),
        user: objectPath.get(entry, 'data.authUser.userName', ''),
        count: objectPath.get(entry, 'data.products.count', 0),
        status: objectPath.get(entry, 'variant', 'success'),
        createdon: objectPath.get(entry, 'endedOn', {})
      })))

      const fields = computed(() => [
        {
          key: 'id',
          label: capitalize(transChoice('global.id', 0)),
          sortable: false,
          tdClass: 'log-id'
        },
        {
          key: 'user',
          label: capitalize(transChoice('global.user', 0)),
          sortable: false,
          tdClass: 'log-title'
        },
        {
          key: 'count',
          label: capitalize(transChoice('global.product', 2)),
          sortable: false,
          tdClass: 'log-count',
          thClass: 'log-count-head'
        },
        {
          key: 'status',
          label: capitalize(transChoice('global.status', 0)),
          sortable: false,
          tdClass: 'log-status',
          thClass: 'log-status-head'
        },
        {
          key: 'createdon',
          label: capitalize(transChoice('global.date', 0)),
          sortable: false,
          tdClass: 'log-createdon',
          thClass: 'log-createdon-head'
        },
        {
          key: 'actions',
          label: capitalize(transChoice('global.action', 0)),
          sortable: false,
          tdClass: 'log-createdon',
          thClass: 'log-createdon-head'
        }
      ])

      return {
        startSync,
        trans,
        transChoice,
        syncIsActive,
        lastSyncStart,
        lastSyncEnd,
        syncTotal,
        syncProgress,
        syncProcessed,
        syncUnchanged,
        syncNew,
        syncFailed,
        syncUpdated,
        momentFullDate,
        momentHoursMinutesSeconds,
        // Log list specifics
        items,
        fields,
        listProps,
        loadMore
      }
    }
  }
</script>

<style lang="scss">
  table {
    &#synclog {
      td {
        vertical-align: middle;
      }
    }
  }

  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
